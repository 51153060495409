<template>
    <div
        v-if="showFooter"
        class="relative w-full bg-neutral text-neutral-content-muted"
    >
        <footer class="container p-8 mx-auto text-base footer">
            <aside class="space-y-4">
                <header class="flex items-center gap-2 -mt-1 footer-title">
                    <Logo class="w-auto h-10" />
                </header>
                <div>
                    <p>{{ $t('footer.left.platform') }}</p>
                    <p>{{ $t('footer.left.selling_and_trading') }}</p>
                </div>
                <div>
                    <p>{{ $t('footer.left.lets_connect') }}</p>
                </div>
                <div class="flex items-center gap-4">
                    <a
                        v-if="socialLinks.discord"
                        :href="socialLinks.discord"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <DiscordLogoIcon class="w-10 h-10" />
                    </a>
                    <a
                        v-if="socialLinks.youtube"
                        :href="socialLinks.youtube"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <YoutubeLogoIcon class="w-10 h-10" />
                    </a>
                    <a
                        v-if="socialLinks.twitter"
                        :href="socialLinks.twitter"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <XLogoIcon class="w-10 h-10" />
                    </a>
                    <a
                        v-if="socialLinks.instagram"
                        :href="socialLinks.instagram"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <InstagramLogoIcon class="w-10 h-10" />
                    </a>
                </div>
            </aside>
            <nav class="space-y-4">
                <header class="footer-title">
                    {{ $t('footer.center.title') }}
                </header>
                <Link class="link link-hover" :href="route('pricing')">
                    {{ $t('footer.center.pricing') }}
                </Link>
                <Link
                    class="link link-hover"
                    :href="route('pricing') + '#reasons-to-sell'"
                >
                    {{
                        $t('footer.center.why_to_sell', {
                            app: applicationName,
                        })
                    }}
                </Link>
                <Link
                    class="link link-hover"
                    :href="route('pricing') + '#reasons-to-buy'"
                >
                    {{
                        $t('footer.center.why_to_buy', { app: applicationName })
                    }}
                </Link>
                <Link
                    class="link link-hover"
                    :href="route('marketplace.index')"
                >
                    {{ $t('footer.center.discover_sneakers') }}
                </Link>
                <Link class="link link-hover" :href="route('faq')">
                    {{ $t('footer.center.faq') }}
                </Link>
                <a
                    class="link link-hover"
                    :href="socialLinks.discord"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    {{ $t('footer.center.contact') }}
                </a>
            </nav>
            <form class="space-y-4">
                <header class="footer-title">
                    {{ $t('footer.right.title') }}
                </header>
                <Link class="link link-hover" :href="route('about-us')">
                    {{ $t('footer.right.about_us') }}
                </Link>
                <Link class="link link-hover" :href="route('terms-of-use')">
                    {{ $t('footer.right.terms_of_use') }}
                </Link>
                <Link
                    class="link link-hover"
                    :href="route('terms-and-conditions')"
                >
                    {{ $t('footer.right.terms_and_conditions') }}
                </Link>
                <Link class="link link-hover" :href="route('privacy-policy')">
                    {{ $t('footer.right.privacy_policy') }}
                </Link>
                <Link class="link link-hover" :href="route('imprint')">
                    {{ $t('footer.right.imprint') }}
                </Link>
            </form>
        </footer>
        <footer
            class="container px-8 py-4 mx-auto text-sm border-t footer border-neutral-content-muted"
        >
            <aside>
                <p>© {{ applicationName }}</p>
            </aside>
        </footer>
    </div>
    <template v-else>
        <div v-if="currentRoute !== route('imprint')" class="p-8 text-center">
            <Link class="link link-hover" :href="route('imprint')">
                {{ $t('footer.right.imprint') }}
            </Link>
        </div>
    </template>
</template>

<script>
import { usePage, Link } from '@inertiajs/vue3';
import DiscordLogoIcon from '@assets/icons/DiscordLogo.svg?component';
import YoutubeLogoIcon from '@assets/icons/YoutubeLogo.svg?component';
import XLogoIcon from '@assets/icons/X.svg?component';
import InstagramLogoIcon from '@assets/icons/InstagramLogo.svg?component';
import Logo from '@assets/logos/text_white_font_transparent.svg?component';

export default {
    name: 'Footer',
    components: {
        DiscordLogoIcon,
        YoutubeLogoIcon,
        XLogoIcon,
        InstagramLogoIcon,
        Logo,
        Link,
    },
    computed: {
        user() {
            return usePage().props.auth.user;
        },
        socialLinks() {
            return usePage().props.config.socialLinks;
        },
        applicationName() {
            return usePage().props.config.applicationName;
        },
        lockPages() {
            return usePage().props.config.lockPages;
        },
        currentRoute() {
            return usePage().props.ziggy.location;
        },
        showFooter() {
            if (this.lockPages) {
                return this.user;
            }

            return true;
        },
    },
};
</script>
