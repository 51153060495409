<template>
    <BaseLayout>
        <Navbar />
        <main class="navbar-padding">
            <slot />
        </main>
        <Footer />
    </BaseLayout>
</template>

<script>
import BaseLayout from './BaseLayout.vue';
import Navbar from './Partials/Navbar.vue';
import Footer from './Partials/Footer.vue';

export default {
    name: 'DefaultLayout',
    components: {
        BaseLayout,
        Navbar,
        Footer,
    },
};
</script>
